<template>
  <div class="card text-center shadow flex-fill ">
    <div class="card-header mb-2">
      <div class="row">
        <div class="col-12">      
          <span class="h1">{{title}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">    
        <span class="h1 pr-2">{{priceComp}} </span>
        <span class="h3">{{price ? getTrans('messages.per_month') : ''}}</span>
      </div>
    </div>
    <hr v-if="$slots.features" />
    <span class="h3" v-if="$slots.features">{{getTrans('messages.features')}}</span>
    <ul class="list-group list-group-flush" v-if="$slots.features">
      <slot name="features"></slot>
    </ul>    
    <hr v-if="description" />
    <div class="h-100 align-text-bottom" v-if="description">
      <span class="card-text">{{description}}</span>
    </div>
    <div class="card-footer mt-auto">
      <base-button v-if="isSelected === true && !price" type="primary" :disabled="true" :loading="loading">{{getTrans('messages.selected')}}</base-button>
      <base-button v-else-if="isSelected === true" type="primary" @click="cancel" :loading="loading">{{getTrans('messages.cancel')}}</base-button>
      <base-button v-else-if="isSelected" type="pink" :disabled="true" :loading="loading">{{getTrans('messages.available_to')}} {{end_date}}</base-button>
      <base-button v-else type="primary" @click="click" :loading="loading">{{getTrans('messages.select_plan')}}</base-button>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: 'feature-card',
  emits: ['click', 'cancel'],
  props: {
    title: {
      type: String,
    },
    price: {
      type: [String, Number],
    },
    features: {
      type: Array,
      default: ()=>{
        return [];
      }
    },
    description: {
      type: String,
    },
    isSelected: {
      type: [Boolean, String],
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  computed: {
    priceComp() {
      return this.price ? '$'+this.price : '$0';
    },
    end_date() {
      if (this.isSelected) {
        return moment(this.isSelected).format('MMM Do');
      }
      return "";
    }
  },
  methods: {
    click() {
      this.$emit('click');
    },
    cancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>