<template>
  <div ref="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center pb-2">
        <span class="h1">
          {{getTrans('messages.choose_subscription')}}
        </span>
      </div>
    </div>  
    <div class="row" v-if="type==='studio'">
      <div class="d-flex" :class="{'col-3': fourWide, 'col-12 col-lg-6 col-xl-8 offset-xl-2 order-12': !fourWide}">
        <feature-card 
          :title="'Free Account'" 
          :price="0" 
          :description="'Best for testing out parts of our service'"
          @click="selectFree"
          :isSelected="planIds.free == getCurrent?.subscription_plan_id ? (getCurrent.canceled_at ? getCurrent.end_date : true) : false" 
          :loading="loading"
        >
          <template v-slot:features>
            <feature-item>{{getTrans('messages.traffic_on_demand')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_analytics')}}</feature-item>
          </template>
        </feature-card>
      </div>
      <div class="d-flex" :class="{'col-3': fourWide, 'col-12 col-lg-6 col-xl-4': !fourWide}">
        <feature-card 
          :title="plans[planIds.basic].name"
          :price="getPlan(planIds.basic)?.amount" 
          :features="plans[planIds.basic].features" 
          :description="plans[planIds.basic].description"
          @click="select(planIds.basic)"
          @cancel="securionPayCancel(planIds.basic)"
          :isSelected="planIds.basic == getCurrent?.subscription_plan_id ? (getCurrent.canceled_at ? getCurrent.end_date : true) : false" 
          :loading="loading"
        >
          <template v-slot:features>
            <feature-item>{{getTrans('messages.traffic_on_demand')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_analytics')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_on_subscription')}}</feature-item>
            <feature-item>{{getTrans('messages.studio_console')}}</feature-item>
            <feature-item :pill="plans[planIds.basic].profiles_in_ads">{{getTransChoise('messages.profiles_in_ads', 75)}}</feature-item>
            <feature-item :pill="plans[planIds.basic].profiles_in_ads">{{getTransChoise('messages.profiles_indexed', 75)}}</feature-item>
            <feature-item :pill="100">{{getTransChoise('messages.free_tokens_month')}}</feature-item>
            <feature-item :pill="'90%'">{{getTransChoise('messages.ondemand_aff_commission')}}</feature-item>
            <feature-item>{{getTrans('messages.promo_tools')}}</feature-item>
            <feature-item :pill="'80%'">{{getTransChoise('messages.promotools_commission')}}</feature-item>
            <feature-item>{{getTrans('messages.custom_traffic_packages')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_to_whitelabel')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_to_affiliate')}}</feature-item>
          </template>
        </feature-card>
      </div>
      <div class="d-flex" :class="{'col-3': fourWide, 'col-12 col-lg-6 col-xl-4': !fourWide}">
        <feature-card 
          :title="plans[planIds.premium].name"
          :price="getPlan(planIds.premium)?.amount" 
          :features="plans[planIds.premium].features" 
          :description="plans[planIds.premium].description"
          @click="select(planIds.premium)"
          @cancel="securionPayCancel(planIds.premium)"
          :isSelected="planIds.premium == getCurrent?.subscription_plan_id ? (getCurrent.canceled_at ? getCurrent.end_date : true) : false" 
          :loading="loading"
        >
          <template v-slot:features>
            <feature-item>{{getTrans('messages.traffic_on_demand')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_analytics')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_on_subscription')}}</feature-item>
            <feature-item>{{getTrans('messages.studio_console')}}</feature-item>
            <feature-item :pill="plans[planIds.premium].profiles_in_ads">{{getTransChoise('messages.profiles_in_ads', 75)}}</feature-item>
            <feature-item :pill="plans[planIds.premium].profiles_in_ads">{{getTransChoise('messages.profiles_indexed', 75)}}</feature-item>
            <feature-item :pill="400">{{getTransChoise('messages.free_tokens_month')}}</feature-item>
            <feature-item :pill="'90%'">{{getTransChoise('messages.ondemand_aff_commission')}}</feature-item>
            <feature-item>{{getTrans('messages.promo_tools')}}</feature-item>
            <feature-item :pill="'90%'">{{getTransChoise('messages.promotools_commission')}}</feature-item>
            <feature-item>{{getTrans('messages.custom_traffic_packages')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_to_whitelabel')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_to_affiliate')}}</feature-item>
            <feature-item :pill="'5%'">{{getTrans('messages.bonus_on_purchase')}}</feature-item>
          </template>
        </feature-card>
      </div>
      <div class="d-flex" :class="{'col-3': fourWide, 'col-12 col-lg-6 col-xl-4': !fourWide}"> 
        <feature-card 
          :title="plans[planIds.pro].name"
          :price="getPlan(planIds.pro)?.amount" 
          :features="plans[planIds.pro].features" 
          :description="plans[planIds.pro].description"
          @click="select(planIds.pro)"
          @cancel="securionPayCancel(planIds.pro)"
          :isSelected="planIds.pro == getCurrent?.subscription_plan_id ? (getCurrent.canceled_at ? getCurrent.end_date : true) : false" 
          :loading="loading"
        >
          <template v-slot:features>
            <feature-item>{{getTrans('messages.traffic_on_demand')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_analytics')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_on_subscription')}}</feature-item>
            <feature-item>{{getTrans('messages.studio_console')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].profiles_in_ads">{{getTrans('messages.profiles_in_ads')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].profiles_in_ads">{{getTrans('messages.profiles_indexed')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].free_tokens_month">{{getTrans('messages.free_tokens_month')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].ondemand_aff_commission">{{getTrans('messages.ondemand_aff_commission')}}</feature-item>
            <feature-item>{{getTrans('messages.promo_tools')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].promotools_commission">{{getTrans('messages.promotools_commission')}}</feature-item>
            <feature-item>{{getTrans('messages.custom_traffic_packages')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_to_whitelabel')}}</feature-item>
            <feature-item>{{getTrans('messages.traffic_to_affiliate')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].bonus_on_purchase">{{getTrans('messages.bonus_on_purchase')}}</feature-item>
            <feature-item :pill="plans[planIds.pro].whitelabel_aff_commission">{{getTrans('messages.whitelabel_aff_commission')}}</feature-item>
          </template>
        </feature-card>       
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FeatureCard from "@/views/Subscription/FeatureCard"
import FeatureItem from "@/views/Subscription/FeatureItem"
import SecurionMixin from "./SecurionMixin.vue"
export default {
  name: 'subscription-overview',
  components: {
    FeatureCard,
    FeatureItem,
  },
  mixins: [SecurionMixin],
  data() {
    return {
      minHeight: 0,
      fourWide: false,
      planIds: {}
    }
  },
  computed: {
    ...mapGetters("SubscriptionPlan", {
      getPlans:'getData', 
      getPlan: 'getPlan',
      loading: 'loading',
    }),
    ...mapGetters("User", {
      getUser: 'getUser', 
      userCan: 'can', 
      getCurrent: 'currentSubscriptionPlan',
    }),
    type() {
      return 'studio';
    },
    plans() {
      return plans;
    },
    isModel() {
      return this.userCan('subscription-plans-model') ? true : false;
    }
  },
  watch: {
    getUser: {
      handler() {
        this.updateIds()
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    updateIds() {
      this.planIds = {
        basic: this.isModel ? 7 : 1,
        premium: this.isModel ? 8 : 2,
        pro: this.isModel ? 9 : 3,
        free: this.isModel ? 14 : 13,
      };
    },
    select(id) {
      let plan = this.getPlan(id);
      this.securionPayBuyPlan(plan);
    },
    selectFree() {
      let plan = this.getPlan(this.planIds.free);
      this.securionPayFreePlan(plan)
    },
    resizeHandler() {
      this.$nextTick(()=>{
        this.fourWide = this.$refs.container?.clientWidth > 1400;
      })
    }
  },
  mounted() {
    this.resizeHandler();
    window.addEventListener("resize",  this.resizeHandler);
    this.$store.dispatch('SubscriptionPlan/get').then(()=>{
      this.updateIds();
    });
  },
  unmounted() {
    window.removeEventListener("resize",  this.resizeHandler);
  }
}
const plans = {
  13: {
    name: 'free',
    type: 'studio',
  },
  14: {
    name: 'free',
    type: 'model',
  },
  1: {
    name: 'Basic',
    type: 'studio',
    description: 'Best for small studios',
    profiles_in_ads: 5,
    free_tokens_month: 100,
    ondemand_aff_commission: '90%',
    promotools_commission: '80%',
  },
  2: {
    name: 'Premium',
    type: 'studio',
    profiles_in_ads: 15,
    free_tokens_month: 400,
    ondemand_aff_commission: '90%',
    promotools_commission: '90%',
    bonus_on_purchase: '5%',
  },
  3: {
    name: 'Pro',
    type: 'studio',
    profiles_in_ads: 75,
    free_tokens_month: 1200,
    ondemand_aff_commission: '90%',
    promotools_commission: '100%',
    bonus_on_purchase: '15%',
    whitelabel_aff_commission: '100%'
  },
  7: {
    name: 'Basic',
    type: 'model',
    description: '',
    profiles_in_ads: 1,
    free_tokens_month: 20,
    ondemand_aff_commission: '90%',
    promotools_commission: '80%',
  },
  8: {
    name: 'Premium',
    type: 'model',
    profiles_in_ads: 5,
    free_tokens_month: 70,
    ondemand_aff_commission: '90%',
    promotools_commission: '90%',
    bonus_on_purchase: '5%',
  },
  9: {
    name: 'Pro',
    type: 'model',
    profiles_in_ads: 15,
    free_tokens_month: 300,
    ondemand_aff_commission: '90%',
    promotools_commission: '100%',
    bonus_on_purchase: '15%',
    whitelabel_aff_commission: '100%'
  },
};
</script>

<style>

</style>