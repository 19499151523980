<template>
<li class="list-group-item">
  <div class="d-flex align-items-center">
    <div class="col-3 text-center">
      <div v-if="!pill"
          class="icon icon-xs icon-shape bg-gradient-green shadow rounded-circle text-white"
      >
        <i class="fas fa-check"></i>
      </div>    
      <div v-else class="bg-gradient-green shadow is-round text-white text-center"><b>{{pill}}</b></div>
    </div>
    <div class="col-9 text-left">
      <span class=""><slot></slot></span>
    </div>
  </div>
</li>
</template>

<script>
export default {
  name: 'feature-item',
  props: {
    pill: {

    }
  }
}
</script>

<style scoped>
.badge {
  font-size: 15px;
}
.is-round {
  border-radius: 9999px;
  white-space: nowrap;
}
</style>