<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters('User', ['getUser']),
  },
  methods: {
    securionPayBuyPlan(plan) {
      return new Promise((resolve, reject) => { // <- need this for securion events
        return this.$store.dispatch('SubscriptionPlan/buyPlan', plan).then(response => {
          /* eslint-disable-next-line */
          SecurionpayCheckout.key = process.env.VUE_APP_SECURIONPAY_PUBLIC_KEY;
          
          /* eslint-disable-next-line */
          SecurionpayCheckout.success = result => {
            this.$store.dispatch('SubscriptionPlan/confirmPayment', result.charge.id).then(data => {
              if (data.result == false) {
                this.$swal.fire({
                  icon: 'error',
                  text: this.getTrans('messages.payment_declined'),
                });
                reject(this.getTrans('messages.payment_declined'));
              } else {
                this.$gtag.event('Sale', 'Subscription', plan.title, Math.round(plan.amount));
                this.$swal.fire({
                  icon: 'success',
                  text: this.getTrans('messages.subscription_updated'),
                  showCancelButton: true,
                  confirmButtonText: this.getTrans('messages.go_to_dashboard'),
                  cancelButtonText: this.getTrans('messages.close'),
                  allowOutsideClick: false,
                }).then(result => {                  
                  this.$store.dispatch('User/refresh');
                  if (result.isConfirmed){
                    this.goToDash();
                  }
                });
              }
            })
            .catch(error => {
              this.displayError(error);
            });
          };
          /* eslint-disable-next-line */
          SecurionpayCheckout.error = (error) => {
            //console.log(error)
            reject(this.getTrans('messages.something_went_wrong'));
          };
          /* eslint-disable-next-line */
          SecurionpayCheckout.close = () => {
            resolve()
          };
          /* eslint-disable-next-line */
          SecurionpayCheckout.open({
            checkoutRequest: response.data.token,
            image: '/img/favicon_96.png',
            key: process.env.VUE_APP_SECURIONPAY_PUBLIC_KEY,
            name: 'DivaTraffic',
            description: plan.title,
            email: this.getUser.email,
          });
        })
        .catch(error => {
          this.displayError(error);
          reject();
        });
      });
    },
    securionPayFreePlan(plan) {
      this.$store.dispatch('SubscriptionPlan/freePlan', plan).then(() => {
        this.$store.dispatch('User/refresh');
        this.$swal.fire({
          icon: 'success',
          text: this.getTrans('messages.subscription_updated'),
          showCancelButton: true,
          confirmButtonText: this.getTrans('messages.go_to_dashboard'),
          cancelButtonText: this.getTrans('messages.close'),
          allowOutsideClick: false,
        }).then(result => {                  
          if (result.isConfirmed){
            this.goToDash();
          }
        });
      }).catch(error => {
        this.$store.dispatch('User/refresh'),
        this.displayError(error);
      })
    },

    securionPayCancel(planId) {
      this.$swal.fire({
        title: this.getTrans('messages.cancel_subscription'),
        text: this.getTrans('messages.are_you_sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTrans('messages.yes'),
        cancelButtonText: this.getTrans('messages.back'),
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('SubscriptionPlan/cancelPlan', planId).then(()=>{
            this.$store.dispatch('User/refresh');
          }).catch(error => {
            this.displayError(error);
          });
        }
      });
    },
    goToDash() {
      this.$router.push({name: 'dashboard'});
    },
    displayError(error) {
      if (typeof error?.response?.data?.error?.message == 'object') {
        this.$swal.fire({
          icon: 'error',
          title: this.getTrans('messages.something_went_wrong'),
          html: Object.values(error.response.data.error.message).join('<br />'),
          allowOutsideClick: false
        });
      } else {
        this.$swal.fire({
          icon: 'error',
          title: this.getTrans('messages.something_went_wrong'),
          text: this.getTrans('extra.server_error_details'),
          allowOutsideClick: false
        });
      }
    }
  },
}
</script>